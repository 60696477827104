@use './palette';

$backgroundPrimary: var(--mm-color-secondLife-background-primary, palette.$P500);
$backgroundPrimaryActive: var(--mm-color-secondLife-background-primaryActive, palette.$P700);
$backgroundPrimaryHover: var(--mm-color-secondLife-background-primaryHover, palette.$P300);
$backgroundSecondary: var(--mm-color-secondLife-background-secondary, palette.$Cr500);
$backgroundSecondaryActive: var(--mm-color-secondLife-background-secondary, palette.$Cr900);
$backgroundSecondaryHover: var(--mm-color-secondLife-background-secondary, palette.$Cr300);
$borderPrimary: var(--mm-color-secondLife-border-primary, palette.$P500);
$borderSecondary: var(--mm-color-secondLife-border-primary, palette.$Cr500);
$textIconPrimary: var(--mm-color-secondLife-textIcon-primary, palette.$P500);
$textIconSecondary: var(--mm-color-secondLife-textIcon-secondary, palette.$Cr500);

:export {
  backgroundPrimary: $backgroundPrimary;
  backgroundPrimaryActive: $backgroundPrimaryActive;
  backgroundPrimaryHover: $backgroundPrimaryHover;
  backgroundSecondary: $backgroundSecondary;
  backgroundSecondaryActive: $backgroundSecondaryActive;
  backgroundSecondaryHover: $backgroundSecondaryHover;
  borderPrimary: $borderPrimary;
  borderSecondary: $borderSecondary;
  textIconPrimary: $textIconPrimary;
  textIconSecondary: $textIconSecondary;
}

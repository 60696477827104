@use './palette';

$backgroundPrimary: var(--mm-color-copper-background-primary, palette.$Co100);
$backgroundSecondary: var(--mm-color-copper-background-secondary, palette.$Co200);
$borderPrimary: var(--mm-color-copper-border-primary, palette.$Co500);
$textIconPrimary: var(--mm-color-copper-textIcon-primary, palette.$Co500);

:export {
  backgroundPrimary: $backgroundPrimary;
  backgroundSecondary: $backgroundSecondary;
  borderPrimary: $borderPrimary;
  textIconPrimary: $textIconPrimary;
}

@use './palette';

$backgroundPrimary: var(--mm-color-error-background-primary, palette.$Er500);
$backgroundPrimaryActive: var(--mm-color-error-background-primaryActive, palette.$Er700);
$backgroundPrimaryHover: var(--mm-color-error-background-primaryHover, palette.$Er200);
$backgroundSecondary: var(--mm-color-error-background-secondary, palette.$Er100);
$backgroundTertiary: var(--mm-color-error-background-tertiary, palette.$Er150);
$borderPrimary: var(--mm-color-error-border-primary, palette.$Er500);
$textIconPrimary: var(--mm-color-error-textIcon-primary, palette.$Er500);

:export {
  backgroundPrimary: $backgroundPrimary;
  backgroundPrimaryActive: $backgroundPrimaryActive;
  backgroundPrimaryHover: $backgroundPrimaryHover;
  backgroundSecondary: $backgroundSecondary;
  backgroundTertiary: $backgroundTertiary;
  borderPrimary: $borderPrimary;
  textIconPrimary: $textIconPrimary;
}

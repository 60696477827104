@use './palette';

$backgroundPrimary: var(--mm-color-gold-background-primary, palette.$Go100);
$backgroundSecondary: var(--mm-color-gold-background-secondary, palette.$Go200);
$borderPrimary: var(--mm-color-gold-border-primary, palette.$Go500);
$textIconPrimary: var(--mm-color-gold-textIcon-primary, palette.$Go500);

:export {
  backgroundPrimary: $backgroundPrimary;
  backgroundSecondary: $backgroundSecondary;
  borderPrimary: $borderPrimary;
  textIconPrimary: $textIconPrimary;
}

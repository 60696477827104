@use './palette';

$backgroundPrimary: var(--mm-color-misc-background-primary, palette.$Vi100);
$backgroundSecondary: var(--mm-color-misc-background-secondary, palette.$Vi200);
$backgroundTertiary: var(--mm-color-misc-background-tertiary, palette.$Vi800);
$borderPrimary: var(--mm-color-misc-border-primary, palette.$Vi800);
$textIconPrimary: var(--mm-color-misc-textIcon-primary, palette.$Vi800);

:export {
  backgroundPrimary: $backgroundPrimary;
  backgroundSecondary: $backgroundSecondary;
  backgroundTertiary: $backgroundTertiary;
  borderPrimary: $borderPrimary;
  textIconPrimary: $textIconPrimary;
}

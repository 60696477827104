@mixin tamagoshi-base() {
  body {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', mm-main, Tahoma, arial, sans-serif;
    font-size: 14px;
  }

  *,
  :after,
  :before {
    box-sizing: border-box;
  }

  button,
  input[type='reset'],
  input[type='button'],
  input[type='submit'] {
    &::-moz-focus-inner {
      border: none;
    }
  }
}
/* stylelint-disable no-invalid-position-at-import-rule */
@mixin tamagoshi-fonts() {
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700');
}

@mixin tamagoshi-icons() {
  @import url('https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp');
}
/* stylelint-enable no-invalid-position-at-import-rule */

@use '@/core/tamagoshiv2/tokens/colors';
@use '@/core/tamagoshiv2/tokens/typography';
@import '@/core/tamagoshi/scss';
@import './fonts';

@include tamagoshi-base;

@supports not (min-height: 100vh) {
  html,
  body,
  #__next {
    height: 100%;
  }
}

@supports (min-height: 100vh) {
  #__next {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    > header + * {
      flex: 1 0 auto;
    }
  }
}

html,
body,
#__next {
  scroll-behavior: smooth;
  scrollbar-gutter: stable;
}

body {
  &[data-overlay='overlay-menu'],
  &[data-overlay='overlay-search'] {
    overflow: hidden;

    @include size-below('md') {
      &[data-is-ios-device='true'] {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
      }
    }
  }
}

html {
  &[data-smoothscroll='disabled'] {
    scroll-behavior: auto;
  }
}

button {
  font-family: 'Open Sans', mm-main, Tahoma, arial, sans-serif;
}

/* stylelint-disable-next-line selector-class-pattern */
#didomi-host {
  contain: content;
  &,
  * {
    /* stylelint-disable-next-line declaration-no-important */
    animation: none !important;
  }
}

@include colors.accent-initialize;
@include typography.initialize;

@use './palette';

$backgroundPrimary: var(--mm-color-loyalty-background-primary, palette.$Lo300);
$backgroundSecondary: var(--mm-color-loyalty-background-secondary, palette.$Lo200);
$borderPrimary: var(--mm-color-loyalty-border-primary, palette.$Lo300);

:export {
  backgroundPrimary: $backgroundPrimary;
  backgroundSecondary: $backgroundSecondary;
  borderPrimary: $borderPrimary;
}

@use './palette';

$backgroundPrimary: var(--mm-color-sustainable-background-primary, palette.$E500);
$backgroundPrimaryActive: var(--mm-color-sustainable-background-primaryActive, palette.$E700);
$backgroundPrimaryHover: var(--mm-color-sustainable-background-primaryHover, palette.$E300);
$backgroundSecondary: var(--mm-color-sustainable-background-secondary, palette.$C500);
$backgroundSecondaryActive: var(--mm-color-sustainable-background-secondary, palette.$C900);
$backgroundSecondaryHover: var(--mm-color-sustainable-background-secondary, palette.$C300);
$borderPrimary: var(--mm-color-sustainable-border-primary, palette.$E500);
$borderSecondary: var(--mm-color-sustainable-border-primary, palette.$C500);
$textIconPrimary: var(--mm-color-sustainable-textIcon-primary, palette.$E500);
$textIconSecondary: var(--mm-color-sustainable-textIcon-secondary, palette.$C500);

:export {
  backgroundPrimary: $backgroundPrimary;
  backgroundPrimaryActive: $backgroundPrimaryActive;
  backgroundPrimaryHover: $backgroundPrimaryHover;
  backgroundSecondary: $backgroundSecondary;
  backgroundSecondaryActive: $backgroundSecondaryActive;
  backgroundSecondaryHover: $backgroundSecondaryHover;
  borderPrimary: $borderPrimary;
  borderSecondary: $borderSecondary;
  textIconPrimary: $textIconPrimary;
  textIconSecondary: $textIconSecondary;
}

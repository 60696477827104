@use './palette';

$backgroundPrimary: var(--mm-color-silver-background-primary, palette.$Si100);
$backgroundSecondary: var(--mm-color-silver-background-secondary, palette.$Si200);
$borderPrimary: var(--mm-color-silver-border-primary, palette.$Si500);
$textIconPrimary: var(--mm-color-silver-textIcon-primary, palette.$Si500);

:export {
  backgroundPrimary: $backgroundPrimary;
  backgroundSecondary: $backgroundSecondary;
  borderPrimary: $borderPrimary;
  textIconPrimary: $textIconPrimary;
}
